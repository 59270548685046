import React from 'react'
import { FaArrowAltCircleRight } from "react-icons/fa";

function Locations() {
    return (
        <div className='locations'>
            <h2>Our stores by city</h2>

            <div className="locations__body">

                <p data-aos="fade-up">
                    Finding a Safestore location near you is simple.
                    Explore the list of cities below to locate your nearest facility and
                    discover the perfect storage solution for your needs.
                </p>

                <div className="locations__body__list">

                    <ul data-aos="fade-up">
                        <li className='locations__body__list__head'>
                            <span>Canada</span>

                        </li>
                        <li>
                            <span>Charlottetown</span>
                            <FaArrowAltCircleRight />
                        </li>
                        <li>
                            <span>Halifax</span>
                            <FaArrowAltCircleRight />
                        </li>
                        <li>
                            <span>Saskatoon</span>
                            <FaArrowAltCircleRight />
                        </li>
                        <li>
                            <span>Sherbrooke</span>
                            <FaArrowAltCircleRight />
                        </li>


                    </ul>

                    <ul data-aos="fade-up">
                        <li className='locations__body__list__head'>
                            <span>United States</span>

                        </li>
                        <li>
                            <span>Tucson (AZ)</span>
                            <FaArrowAltCircleRight />
                        </li>
                        <li>
                            <span>Asheville (NC)</span>
                            <FaArrowAltCircleRight />
                        </li>
                        <li>
                            <span>Honolulu (HI)</span>
                            <FaArrowAltCircleRight />
                        </li>
                        <li>
                            <span>Fort Worth (TX)</span>
                            <FaArrowAltCircleRight />
                        </li>

                    </ul>

                    <ul data-aos="fade-up">
                        <li className='locations__body__list__head'>
                            <span>United Kingdom</span>

                        </li>
                        <li>
                            <span>Bournemouth</span>
                            <FaArrowAltCircleRight />
                        </li>
                        <li>
                            <span>Edinburgh</span>
                            <FaArrowAltCircleRight />
                        </li>
                        <li>
                            <span>Liverpool</span>
                            <FaArrowAltCircleRight />
                        </li>
                        <li>
                            <span>Newcastle</span>
                            <FaArrowAltCircleRight />
                        </li>

                    </ul>

                </div>
            </div>
        </div>
    )
}

export default Locations