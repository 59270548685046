import React from 'react'
import Logo from '../img/logo.png';
import { FaUserShield } from "react-icons/fa";
import { useLocation, useNavigate } from 'react-router-dom';

function Header() {

  const navigate = useNavigate()
  const location = useLocation()
  const route = location.pathname
  console.log(route)

  function handleButton() {
    navigate(route === '/' ? 'login' : '/')
  }

  return (
    <div className='header'>
        <img src={Logo} alt="Logo of Company" />
        <div 
          className="header__signin"
          onClick={() => handleButton()}
        >
            <FaUserShield />
            <span>
              {
                route === '/' ? 'Login' : route === '/login' ? 'Home' : 'Logout'  
              }
              
              </span>
        </div>    
    </div>
  )
}

export default Header