import React from 'react'
import Partner1 from '../img/partner1.png'
import Partner2 from '../img/partner2.png'
import Partner3 from '../img/partner3.png'
import Partner4 from '../img/partner4.png'


function Partners() {


    return (
        <div className = 'partners'>
        <h2> OUR PARTNERS</h2>
        <div className="partners__body slideshow" data-aos="fade-up">
            <img src={Partner1} alt="Partner logo" />
            <img src={Partner2} alt="Partner logo" />
            <img src={Partner3} alt="Partner logo" />
            <img src={Partner4} alt="Partner logo" />
        </div>
    </div >
  )
}

export default Partners