import React from 'react'

function AboutUs() {
    return (
        <div className='aboutus'>
            <h2>Your No.1 for choice in Safe Storage</h2>

            <div className="aboutus__body">
                <p data-aos="fade-up">
                    Are you looking for a safe place to store your valuables?
                    Our safe storage facility offers a secure and convenient solution for people who want to keep their gold,
                    jewelry, and other important items out of harm's way.
                </p>

                <p data-aos="fade-up">
                    Our state-of-the-art facility is equipped with 24/7 maximum security,
                    including CCTV surveillance, alarm systems, and on-site security guards.
                    We also use high-quality safes that are designed to withstand even the most sophisticated attempts at theft.
                </p>

                <p data-aos="fade-up">
                    When you choose our safe storage service, you will have the key to your safe.
                    This means that you can come to our office at any time to access your belongings.
                    We are open 24/7, so you can always get to your valuables when you need them.
                </p>
                <p data-aos="fade-up">
                    Our safe storage service is affordable and easy to use.
                    We offer a variety of safe sizes to choose from, so you can find the perfect solution for your needs.
                    We also have a flexible rental policy, so you can rent a safe for as long or as short as you need.
                </p>

                <p data-aos="fade-up">
                    If you are looking for a safe and reliable place to store your valuables, we are the perfect choice.
                    Contact us today to learn more about our safe storage services.
                </p>
            </div>

        </div>
    )
}

export default AboutUs