import React from 'react'
import SectionImg from '../img/benefits.jpg'
import { FaLock } from "react-icons/fa6";
import { BiSolidCctv } from "react-icons/bi";
import { FaKey } from 'react-icons/fa';
import { GiRingingAlarm } from 'react-icons/gi';

function Benefits() {
    return (
        <div className='benefits'>
            <h2>Keys Benefits from IC Security</h2>

            <div className="benefits__body">
                <div className="benefits__body__img" data-aos="fade-up">
                    <img src={SectionImg} alt="Section Representation" />
                </div>

                <div className="benefits__body__text" data-aos="fade-up">
                    <p>
                        At IC Security, we provide a variety of storage solutions tailored to meet your needs,
                        offering units that range from compact document and simple assets spaces to large warehouse-sized units.
                        Most of our storage units are located indoors for optimal protection,
                        with some locations also featuring convenient external drive-up units for added ease of access.
                    </p>

                    <p>
                        Our facilities are equipped with 24-hour security, easy access,
                        and a dedicated team to ensure your belongings are kept safe.
                    </p>

                    <p>
                        We also offer competitive introductory rates for new customers,
                        as well as long-term discounts for those staying over a year, making our storage options both flexible and cost-effective.
                    </p>

                    <p>
                        <ul>
                            <li>
                                <div className="benefits__body__text__iconbox">
                                    <FaLock />
                                </div>
                                <span>Clean, dry, secure & safe</span>
                            </li>

                            <li>
                                <div className="benefits__body__text__iconbox">
                                    <BiSolidCctv />
                                </div>
                                <span>24 hour CCTV & security</span>
                            </li>

                            <li>
                                <div className="benefits__body__text__iconbox">
                                    <FaKey />
                                </div>
                                <span>Sole key holder</span>
                            </li>

                            <li>
                                <div className="benefits__body__text__iconbox">
                                    <GiRingingAlarm />
                                </div>
                                <span>Intruder Alarm system</span>
                            </li>
                        </ul>
                    </p>
                </div>
            </div>
        </div>
    )
}

export default Benefits