import React from 'react'

function Services() {
    return (
        <div className='services'>

            <h2>OUR SERVICES</h2>

            <div className="services__container">

                <div className="services__item" data-aos="flip-left" data-aos-duration="1500">

                    <div class="card">
                        <div class="card__side card__side--front">
                            <div class="card__picture card__picture--1">
                                &nbsp;
                            </div>
                            <h4 class="card__heading">
                                <span class="card__heading-span card__heading-span--1">Personal Safe Storage</span>
                            </h4>
                            <div class="card__details">
                                <ul>
                                    <li>Small to medium safe sizes</li>
                                    <li>Convenient self-access</li>
                                    <li>Suitable for infrequent visits</li>
                                    <li>Competitive pricing for personal use</li>
                                </ul>
                            </div>
                        </div>


                        <div class="card__side card__side--back card__side--back-1">
                            <div class="card__cta">
                                <div class="card__price-box">
                                    <p class="card__price-only">Details</p>
                                    <p class="card__price-value">
                                        This category caters to individuals storing personal valuables like jewelry, documents, and small electronics.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="services__item" data-aos="flip-up" data-aos-duration="1500">
                    <div class="card">
                        <div class="card__side card__side--front">
                            <div class="card__picture card__picture--2">
                                &nbsp;
                            </div>
                            <h4 class="card__heading">
                                <span class="card__heading-span card__heading-span--2">Business Safe Storage</span>
                            </h4>
                            <div class="card__details">
                                <ul>
                                    <li>Choose from variety of safe sizes</li>
                                    <li>Enhanced security features for business-critical items</li>
                                    <li>Flexible access options, including on-demand visits</li>
                                    <li>Professional handling and storage of assets.</li>
                                </ul>
                            </div>

                        </div>
                        <div class="card__side card__side--back card__side--back-2">
                            <div class="card__cta">
                                <div class="card__price-box">
                                    <p class="card__price-only">Details</p>
                                    <p class="card__price-value">
                                        Designed for businesses and entrepreneurs needing secure storage for documents, inventory, and equipment.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="services__item" data-aos="flip-right" data-aos-duration="1500">
                    <div class="card">
                        <div class="card__side card__side--front">
                            <div class="card__picture card__picture--3">
                                &nbsp;
                            </div>
                            <h4 class="card__heading">
                                <span class="card__heading-span card__heading-span--3">High-Value Safe Storage</span>
                            </h4>
                            <div class="card__details">
                                <ul>
                                    <li>Large safes with advanced security systems</li>
                                    <li>Controlled temperature and humidity</li>
                                    <li>Insurance options for added protection</li>
                                    <li>Dedicated account managers</li>
                                </ul>
                            </div>

                        </div>
                        <div class="card__side card__side--back card__side--back-3">
                            <div class="card__cta">
                                <div class="card__price-box">
                                    <p class="card__price-only">Details</p>
                                    <p class="card__price-value">
                                        This premium service is tailored for high-value items like precious metals, art, and
                                        collectibles requiring specialized handling and maximum security.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Services