import React, { useState } from 'react'
import { FcGoogle } from "react-icons/fc";
import { FaStarHalf, FaStar } from "react-icons/fa6";

const ratings = [
    {
        name: "Oliver Smith",
        date: "2024-10-17",
        rating: 5,
        text: "I've been using this safe storage facility for several months now, and I'm extremely satisfied. The security measures are top-notch, the staff is incredibly helpful, and the facility is always clean and well-maintained. I feel confident that my valuables are safe here."
    },
    {
        name: "Emily Johnson",
        date: "2024-10-15",
        rating: 4,
        text: "Overall, I've had a positive experience with this safe storage company. The facility is secure and convenient, and the staff is friendly. However, I did encounter a slight delay in access during peak hours, which was a bit frustrating. Other than that, I'm satisfied with the service."
    },
    {
        name: "Thomas Williams",
        date: "2024-10-12",
        rating: 5,
        text: "I can't recommend this safe storage company enough. The location is perfect for me, and the security measures are top-notch. I've never felt more confident about the safety of my belongings. The staff is always friendly and helpful, and the facility is clean and well-maintained."
    },
    {
        name: "Olivia Taylor",
        date: "2024-10-09",
        rating: 4,
        text: "The safe storage facility is overall good, but I do have a few minor criticisms. The rental fees are a bit higher than I expected, and the hours of operation could be more flexible. However, the security measures are excellent, and the staff is friendly and helpful."
    },
    {
        name: "Daniel Brown",
        date: "2024-10-07",
        rating: 5,
        text: "I've been using this safe storage facility for over a year now, and I've never had any issues. The staff is always friendly and accommodating, and the facility is secure and easy to access. I highly recommend this company to anyone looking for a reliable and affordable safe storage solution."
    },
    {
        name: "Sophia Jones",
        date: "2024-10-05",
        rating: 4,
        text: "The location of this safe storage facility is very convenient for me, but I wish the hours of operation were longer. It would be helpful to have access to the facility on weekends or late evenings. Otherwise, I've been satisfied with the service."
    },
    {
        name: "Liam Davis",
        date: "2024-10-03",
        rating: 5,
        text: "I'm extremely impressed with this safe storage company. The value for the price is excellent, and the facility is always clean and well-maintained. The security measures are top-notch, and I feel confident that my belongings are safe. I would highly recommend this company to anyone in need of safe storage."
    },
    {
        name: "Ava Miller",
        date: "2024-10-01",
        rating: 4,
        text: "Overall, I'm satisfied with the safe storage service. The security measures are excellent, and the facility is clean and well-maintained. However, the rental process could be a bit simpler. It would be helpful if there were more online options for managing my account."
    },
    {
        name: "Noah Wilson",
        date: "2024-09-29",
        rating: 5,
        text: "I've been using this safe storage facility for several years now, and I've always been impressed with the service. The facility is secure and convenient, and the staff is friendly and helpful. I've never had any issues with access or security. I highly recommend this company to anyone in need of safe storage."
    },
    {
        name: "Emma Carter",
        date: "2024-09-27",
        rating: 4,
        text: "I've been using this safe storage facility for a few months now, and I've been overall satisfied. The facility is clean and well-maintained, and the staff is friendly and helpful. However, I did encounter a slight issue with access during one visit, which was resolved quickly. Overall, I would recommend this company."
    },
    {
        name: "Benjamin Harris",
        date: "2024-09-25",
        rating: 5,
        text: "I'm extremely impressed with this safe storage facility. The security measures are top-notch, and I feel completely confident that my belongings are safe. The staff is always friendly and helpful, and the facility is clean and well-maintained. I highly recommend this company to anyone in need of safe storage."
    },
    {
        name: "Mia Johnson",
        date: "2024-09-23",
        rating: 4,
        text: "The safe storage facility is overall good, but the parking can be a bit tight, especially during peak hours. It can be difficult to find a spot sometimes. Other than that, I've been satisfied with the service. The facility is clean and well-maintained, and the staff is friendly and helpful."
    },
    {
        name: "Jacob Smith",
        date: "2024-09-21",
        rating: 5,
        text: "I've been using this safe storage facility for over a year now, and I've never had any issues. The staff is always friendly and helpful, and the facility is secure and easy to access. The security measures are top-notch, and I feel completely confident that my belongings are safe. I highly recommend this company."
    },
    {
        name: "Lily Brown",
        date: "2024-09-19",
        rating: 4,
        text: "The rental process was easy and straightforward. The facility is clean and well-maintained, and the staff is friendly and helpful. However, I wish the hours of operation were a bit longer. It would be convenient to have access to the facility on weekends or late evenings."
    },
    {
        name: "Ethan Davis",
        date: "2024-09-17",
        rating: 5,
        text: "I'm extremely impressed with this safe storage company. The value for the price is excellent, and the facility is always clean and well-maintained. The security measures are top-notch, and I feel confident that my belongings are safe. I would highly recommend this company to anyone in need of safe storage."
    },
    {
        name: "Olivia Wilson",
        date: "2024-09-15",
        rating: 4,
        text: "The location of this safe storage facility is very convenient for me, but the hours of operation could be longer. It would be helpful to have access to the facility on weekends or late evenings. Other than that, I've been satisfied with the service."
    },
    {
        name: "Noah Carter",
        date: "2024-09-13",
        rating: 5,
        text: "I've been using this safe storage facility for several years now, and I've always been impressed with the service. The facility is secure and convenient, and the staff is friendly and helpful. I've never had any issues with access or security. I highly recommend this company to anyone in need of safe storage."
    },
    {
        name: "Emma Taylor",
        date: "2023-12-25",
        rating: 5,
        text: "I've been using this safe storage facility for a few months now, and I'm extremely satisfied. The security measures are top-notch, the staff is incredibly helpful, and the facility is always clean and well-maintained. I feel confident that my valuables are safe here."
    }
];

function Reviews() {

    const [viewNo, setViewNo] = useState(6)

    return (
        <div className='reviews'>
            <h2>OUR REVIEWS</h2>

            <div className="reviews__head" data-aos="fade-up">
                <div className="reviews__head__google">
                    <FcGoogle />
                    <span>Google</span>
                </div>
                <div className="reviews__head__rating">
                    <p>4.5</p>
                    <div className="reviews__head__rating__stars">
                        <FaStar />
                        <FaStar />
                        <FaStar />
                        <FaStar />
                        <FaStarHalf />
                    </div>
                    <span>(3,187)</span>
                </div>

            </div>

            <div className="reviews__body">

                <div className="reviews__body__list">

                    {
                        ratings.slice(0, viewNo).map((item, index) => <ReviewItem data={item} />)
                    }

                </div>

                <button
                    className='btn reviews__btn'
                    onClick={() => setViewNo( viewNo === 6 ? ratings.length : 6)}
                >
                    {
                        viewNo === 6 ? 'Read More' : 'Collpase'
                    }
                    
                </button>

            </div>
        </div>
    )
}

export default Reviews

const ReviewItem = (props) => {
    return (
        <div className="reviews__item" data-aos="fade-up">
            <div className="reviews__item__head">
                <h5>{props.data.name}</h5>
                <p>{props.data.date}</p>
            </div>
            <div className="reviews__item__content">
                <div className="reviews__item__content__stars">
                    {
                        ([...Array(props.data.rating).keys()].map(i => i)).map(item => <FaStar />)
                    }
                    {

                        ([...Array(5 - props.data.rating).keys()].map(i => i)).map(item => <FaStarHalf />)
                    }
                </div>
                <p>
                    {props.data.text}
                </p>
            </div>

        </div>
    )
}