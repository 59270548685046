import React, { useState } from 'react'
import { IoMdAddCircle, IoMdDocument } from "react-icons/io";
import { FaHourglassStart } from "react-icons/fa";
import { GiTakeMyMoney } from 'react-icons/gi';
import { MdSecurity } from 'react-icons/md';

const inlineStyle = {
    display: 'block',
    width: 'auto'
}

const FAQS__started = [
    {
        title: 'How do I get started with renting a storage unit?',
        content: 'Getting started is easy! You can reserve a unit online, over the phone, or by visiting one of our conveniently located stores. Our friendly staff will guide you through the process and help you choose the best storage solution for your needs.'
    },
    {
        title: 'How do I choose the right storage unit size?',
        content: 'Choosing the right storage unit depends on the quantity and size of the items you wish to store. We offer a range of unit sizes, and our team can help you find the most suitable size. We also have an online size estimator tool to guide you.'
    }
]

const FAQS__contracts = [
    {
        title: 'What are Safestore’s opening times?',
        content: `
                Most of our Safestore locations are open at the following times:
                Monday to Saturday: 8am - 6pm
                Thursday: 8am - 8pm
                Sunday: 10am - 4pm
                However, please note that the opening times for our Bath, St John's Wood, Chelsea, Eastleigh, and Christchurch stores may differ. We recommend checking our website for the specific opening times of your local store to ensure the most up-to-date information.
                `
    },
    {
        title: 'How often can I access my storage unit?',
        content: 'We offer unlimited daily access during our normal opening hours, 7 days a week. This flexibility ensures that your belongings are within reach when you need them.',
    },
    {
        title: 'How long do I need to commit to renting a storage unit?',
        content: 'Our storage contracts are flexible. You can rent a unit for as long as you need, with no long-term commitment required. Simply give us notice when you are ready to move out.'
    },
]

const FAQS__cost = [
    {
        title: 'Are there any hidden fees?',
        content: `We believe in transparent pricing, with no hidden costs. There’s no deposit required, and our pricing structure is straightforward, so you know exactly what you’re paying for.`  
    },
    {
        title: 'Can I buy packing materials at your facility?',
        content: `Yes, we offer a wide range of packing materials, including boxes, tape, bubble wrap, and protective covers, available for purchase at our stores.`
    }
]

const FAQS__security = [
    {
        title: 'Do you offer insurance for stored items?',
        content: `Yes, we offer SafeProtect, a comprehensive protection plan to cover your belongings while they are in storage. Our team can provide more information about the coverage options available.`
    },
    {
        title: 'What can I store in a storage unit?',
        content: `You can store a wide variety of items, including jewellery, gold, diamond, personal belongings, documents, and business inventory. However, there are some restrictions—items such as perishable goods, flammable materials, and illegal substances are not permitted. Please consult our full list of prohibited items or speak to our team for more details.`
    }
]

function FAQS() {
    return (
        <div className='faqs'>
            <h2>Frequently Asked Questions</h2>

            <div className="faqs__body">

                <div className="faqs__body__section">

                    <div className="faqs__body__head">
                        <FaHourglassStart />
                        <h3>Getting Started</h3>
                    </div>

                    {
                        FAQS__started.map((item, index) => <FAQItem data={item} />)
                    }

                </div>

                <div className="faqs__body__section">

                    <div className="faqs__body__head">
                        <IoMdDocument />
                        <h3>Storage Access and Contracts</h3>
                    </div>

                    {
                        FAQS__contracts.map((item, index) => <FAQItem data={item} />)
                    }

                </div>

                <div className="faqs__body__section">

                    <div className="faqs__body__head">
                        <GiTakeMyMoney />
                        <h3>Costs and Materials</h3>
                    </div>

                    {
                        FAQS__cost.map((item, index) => <FAQItem data={item} />)
                    }

                </div>

                <div className="faqs__body__section">

                    <div className="faqs__body__head">
                        <MdSecurity />
                        <h3>Security and Protection</h3>
                    </div>

                    {
                        FAQS__security.map((item, index) => <FAQItem data={item} />)
                    }

                </div>


            </div>
        </div>
    )
}

export default FAQS

const FAQItem = (props) => {
    const [reveal, setReveal] = useState(false)
    return (
        <div className="faqs__item" data-aos="fade-up">
            <div 
                className="faqs__item__head"
                onClick={() => setReveal(!reveal)}
            >
                <h4>{props.data.title}</h4>
                <IoMdAddCircle />
            </div>
            <div 
                className="faqs__item__body"
                style={reveal ? inlineStyle : {}}
            >
                {props.data.content}
            </div>
        </div>
    )
}