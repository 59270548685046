import React from "react";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";

import Home from "./features/Home";
import SafePage from "./features/SafePage";
import Login from "./features/Login";

function Navigator() {
  return (
    <Router>
      <Routes>
        <Route path="/safe" element={<SafePage />} />
        <Route path="/login" element={<Login />} />
        <Route path="/" element={<Home />} />
      </Routes>
    </Router>
  );
}

export default Navigator;
