import React from 'react'
import Step1 from '../img/smart-city.png'
import Step2 from '../img/safe-box-grad.png'
import Step3 from '../img/payment.png'
import Step4 from '../img/keys.png'

function Operation() {
  return (
    <div className='operation'>
        <h2>How IC Security Works</h2>

        <div className="operation__body">

            <div className="operation__item" data-aos="flip-left">
                <img src={Step1} alt="First operation step" />
                <h4>Select Store Location</h4>
                <p>Choose from 28 stores nationwide</p>
            </div>

            <div className="operation__item" data-aos="flip-down">
                <img src={Step2} alt="First operation step" />
                <h4>Choose Safe Size</h4>
                <p>Select from our safe sizes and services</p>
            </div>

            <div className="operation__item" data-aos="flip-up">
                <img src={Step3} alt="First operation step" />
                <h4>Make Payment</h4>
                <p>Select from our safe sizes and services</p>
            </div>

            <div className="operation__item" data-aos="flip-right">
                <img src={Step4} alt="First operation step" />
                <h4>Visit Office</h4>
                <p>Schedule a visit to the store for your keys</p>
            </div>

        </div>
    </div>
  )
}

export default Operation