import React from 'react'
import Wallet from '../img/price-tag.png';
import Customer from '../img/support.png'
import Safe from '../img/safe-box-icon.png'

function WhyICS() {
    return (
        <div className='whyus'>
            <h2>Why IC Security</h2>

            <div className="whyus__body">

                <div className="whyus__item" data-aos="fade-up">
                    <img src={Safe} alt="Item representation" />
                    <div className="whyus__item__details">
                        <h4>28 Stores Nationwide</h4>

                        <p>
                            IC Security have more stores and more choice than any Canada storage provider, including 6 in USA
                        </p>
                    </div>
                </div>

                <div className="whyus__item" data-aos="fade-up">
                    <img src={Wallet} alt="Item representation" />
                    <div className="whyus__item__details">
                        <h4>Lowest Price Guarantee</h4>

                        <p>
                            Want a cheaper and more secure service. We were voted number 1 when it comes to affordable safe services.
                        </p>
                    </div>
                </div>

                <div className="whyus__item" data-aos="fade-up">
                    <img src={Customer} alt="Item representation" />
                    <div className="whyus__item__details">
                        <h4>Excellent Customer Service</h4>
                        <p>
                            Need any information. Our customer service lines are available 24/7 and offers the best with our trained personnels
                        </p>
                    </div>
                </div>

            </div>
        </div>
    )
}

export default WhyICS