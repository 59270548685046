import React from 'react'
import { FaArrowAltCircleRight } from "react-icons/fa";

function Promo() {
    return (

        <div className='promo' data-aos="fade-up">
            <div className="promo__container">
                <div className="promo__left">
                    <p className='promo__left__main'>
                        Get 50% off your storage when you get a quote online today
                    </p>
                    <p className='promo__left__sub'>
                        Then book for free or secure your storage in 3 mins
                    </p>
                </div>

                <div className="promo__right">
                    <div className="promo__right__top">
                        <input type="text" />
                        <button className='btn promo__right__btn'>
                            <span>Get a Quote</span>
                            <FaArrowAltCircleRight />
                        </button>
                    </div>

                    <div className="promo__right__bottom">
                        Applies to all <span>stores</span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Promo