import React from 'react'
import Header from './Header'
import Landing from './Landing'
import SafeUser from './SafeUser'
import Partners from './Partners'
import Footer from './Footer'



function SafePage() {
    return (
        <div className='home'>
            <Header />
            <Landing />
            <SafeUser />
            <Partners />
            <Footer />
        </div>
    )
}

export default SafePage