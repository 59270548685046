import React from 'react'
import Logo from '../img/logo.png'
import { FaPhoneAlt } from "react-icons/fa";
import { IoIosMail } from "react-icons/io";
import { FaLocationPin } from 'react-icons/fa6';

function Footer() {
    return (
        <div className='footer'>

            <div className="footer__head">
                <img src={Logo} alt="Logo of the company" />
                <div className="footer__head__contact">
                    <div className="footer__head__contact__item">
                        <p>+1-210-204-1641</p>
                        <div className="footer__head__contact__item__iconbox">
                            <FaPhoneAlt />
                        </div>

                    </div>

                    <div className="footer__head__contact__item">
                        <p>support@internationalcommercialsecurity.com</p>
                        <div className="footer__head__contact__item__iconbox">
                            <IoIosMail />
                        </div>

                    </div>

                    <div className="footer__head__contact__item">
                        <p>6000 Metrotown BikewayBurnaby, BC, Canada</p>
                        <div className="footer__head__contact__item__iconbox">
                            <FaLocationPin />
                        </div>

                    </div>
                </div>
            </div>

            <div className="footer__body">

                <div className="footer__body__item">
                    <h4>Popular Locations</h4>

                    <ul>
                        <li>London Storage</li>
                        <li>Glasgow Storage</li>
                        <li>Southampton Storage</li>
                        <li>Manchester Storage</li>
                        <li>Portsmouth Storage</li>
                    </ul>
                </div>

                <div className="footer__body__item">
                    <h4>Storage Types</h4>
                    <ul>
                        <li>Personal Storage</li>
                        <li>Business Storage</li>
                        <li>High-Value Storage</li>
                        <li>Equipment Storage</li>
                        <li>Outdoor Storage</li>
                    </ul>
                </div>

                <div className="footer__body__item">
                    <h4>Additional Services</h4>
                    <ul>
                        <li>Boxes & Packaging</li>
                        <li>DHL Service Point</li>
                        <li>Insurance</li>
                        <li>CCTV Installations</li>
                        <li>DropOff Services</li>
                    </ul>
                </div>
            </div>

        </div>
    )
}

export default Footer