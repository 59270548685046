import "./sass/main.scss";
import "react-toastify/dist/ReactToastify.css";
import "aos/dist/aos.css";
import Navigator from "./Navigator";
import AOS from "aos";
function App() {
  AOS.init();
  return <Navigator />;
}

export default App;
