import React from 'react'
import { HashLoader } from 'react-spinners';
import { isMobile } from 'react-device-detect';

function Spinner(props) {

  return (
    <div class="spinner" style={props.showSpinner ? {height: props.height || '70vh', width: isMobile ? props.width : '100%'} : { height: '0' }}>
      <HashLoader color='#333399' loading={props.showSpinner} size={isMobile ? 150 : 200} />
    </div>
  )

}

export default Spinner