import React from 'react'

function Landing() {
    return (
        <div className='landing'>
            <p className='landing__main'>Security of your <span>valuable</span> assets</p>
            <p className='landing__sub'>We got you always</p>
        </div>
    )
}

export default Landing