import React from 'react'
import Header from './Header'
import Landing from './Landing'
import AboutUs from './AboutUs'
import WhyICS from './WhyICS'
import Locations from './Locations'
import Promo from './Promo'
import Operation from './Operation'
import Services from './Services'
import Benefits from './Benefits'
import Reviews from './Reviews'
import FAQS from './FAQS'
import Partners from './Partners';
import Footer from './Footer'

function Home() {
  return (
    <div className='home'>
        <Header />
        <Landing />
        <AboutUs />
        <WhyICS />
        <Locations />
        <Promo />
        <Operation />
        <Services />
        <Benefits />
        <Reviews />
        <FAQS />
        <Partners />
        <Footer />
    </div>
  )
}

export default Home