import React from 'react'
import Header from './Header'
import Landing from './Landing'
import Form from './Form'
import Partners from './Partners'
import Footer from './Footer'

function Login() {
  return (
    <div className='home'>
        <Header />
        <Landing />
        <Form />
        <Partners />
        <Footer />
    </div>
  )
}

export default Login