import React from 'react'
import { useNavigate, useLocation } from 'react-router-dom';
import { RiMoneyPoundCircleFill } from "react-icons/ri";
import { CiCalendarDate } from "react-icons/ci";
import { MdAccessTime, MdEmail, MdPhone } from "react-icons/md";
import { FaPhone, FaToolbox, FaWeightScale } from "react-icons/fa6";
import { ImUserTie } from 'react-icons/im';
import { FaIdCardAlt } from 'react-icons/fa';
import { GoNumber } from 'react-icons/go';
import { AiFillInsurance } from 'react-icons/ai';

function SafeUser(props) {

    const navigate = useNavigate()
    const location = useLocation()
    const data = location.state
    //console.log(data)

    return (
        <div className="user">
            <div className="user__content">
                <h2 className="user__header">
                    Safe Information
                </h2>
                <ul>

                    <li>
                        <ImUserTie />
                        <div className="user__details">
                            <h4>Bearer's Full Name</h4>
                            <p>{data.fullname}</p>
                        </div>
                    </li>
                    <li>
                        <MdPhone />
                        <div className="user__details">
                            <h4>Bearer's Phone</h4>
                            <p>{data.phone}</p>
                        </div>
                    </li>
                    <li>
                        <MdEmail />
                        <div className="user__details">
                            <h4>Bearer's Email</h4>
                            <p>{data.email}</p>
                        </div>
                    </li>
                    <li>
                        <FaIdCardAlt />
                        <div className="user__details">
                            <h4>Bearer's ID type</h4>
                            <p>{data.idtype}</p>
                        </div>
                    </li>
                    <li>
                        <GoNumber />
                        <div className="user__details">
                            <h4>Bearer's ID Number</h4>
                            <p>{data.idnumber}</p>
                        </div>
                    </li>
                    <li>
                        <FaToolbox />
                        <div className="user__details">
                            <h4>Type Of Service</h4>
                            <p>{data.servicetype}</p>
                        </div>
                    </li>
                    <li>
                        <MdAccessTime />
                        <div className="user__details">
                            <h4>Rental Period</h4>
                            <p>{data.period}</p>
                        </div>
                    </li>
                    <li>
                        <FaPhone />
                        <div className="user__details">
                            <h4>Emergency Contact</h4>
                            <p>{data.emergencynumber}</p>
                        </div>
                    </li>
                    <li>
                        <CiCalendarDate />
                        <div className="user__details">
                            <h4>Service Starting Date</h4>
                            <p>{data.startingdate}</p>
                        </div>
                    </li>
                    <li>
                        <AiFillInsurance />
                        <div className="user__details">
                            <h4>Type of Insurance</h4>
                            <p>{data.insurancetype}</p>
                        </div>
                    </li>

                    <li>
                        <FaWeightScale />
                        <div className="user__details">
                            <h4>Total Weight in Kilograms</h4>
                            <p>{data.weight}</p>
                        </div>
                    </li>

                    <li>
                        <RiMoneyPoundCircleFill />
                        <div className="user__details">
                            <h4>Estimated Amount</h4>
                            <p>{data.amount}</p>
                        </div>
                    </li>
                </ul>
            </div>
            <div className="user__btnsection">
                <button
                    className='btn user__btn'
                    onClick={() => navigate('/', { replace: true })}
                >
                    Logout
                </button>
            </div>
        </div>
    )
}

export default SafeUser