import React, { useState } from 'react'
import { IoMdMail } from "react-icons/io";
import { RiLockPasswordFill } from "react-icons/ri";
import { useNavigate, useLocation } from 'react-router-dom';
import Spinner from './Spinner';
import { collection, getDocs, query, where, getFirestore } from "firebase/firestore";
import { ToastContainer, toast } from 'react-toastify';
import _ from 'lodash';

const inlineStyle = {
    display: 'none',
    width: '0',
}


function Form() {
    const navigate = useNavigate()
    const location = useLocation()
    const [showSpinner, setShowSpinner] = useState(false)
    const [email, setEmail] = useState("")
    const [pword, setPword] = useState("")

    const setError = (message) => toast.error(message, { position: 'top-center' })

    function handleLogin() {
        // console.log('')
        navigate('/safe')
    }

    async function handleSubmit(e) {
        e.preventDefault()
        setShowSpinner(true)
        let result = []
        try {

            const db = getFirestore()
            const usersRef = collection(db, "safes")
            const q1 = query(usersRef, where("email", "==", email), where("password", "==", pword));
            const querySnapshot = await getDocs(q1);
            querySnapshot.forEach((doc) => {
                // doc.data() is never undefined for query doc snapshots
                //console.log(doc.id, " => ", doc.data());
                result.push({ id: doc.id, ...doc.data() })
            });

            if (!_.isEmpty(result)) {

                setTimeout(() => {
                    setShowSpinner(false)
                    navigate('/safe', { state: result[0] })
                }, 3000);

            } else {

                setShowSpinner(false)
                setError('Wrong Credentials')
            }

        } catch (error) {
            console.log(error)
            setShowSpinner(false)
            setError('Error logging in. Try later')
            // setError('Wrong tracking number')
        }
    }

    return (
        <div className='login'>

            <Spinner
                showSpinner={showSpinner}
            />

            <form action="" style={showSpinner ? inlineStyle : {}} onSubmit={handleSubmit}>
                <div className="login__form" >
                    <div className="login__form__inputbox">
                        <label htmlFor="email">Email</label>
                        <div className="login__form__input__wrap">
                            <div className="login__form__input__icon__box">
                                <IoMdMail className="login__form__input__icon" />
                            </div>
                            <input
                                type="email"
                                className='login__form__input'
                                name="email"
                                id='email'
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                required
                            />
                        </div>
                    </div>

                    <div className="login__form__inputbox">
                        <label htmlFor="pword">Password</label>
                        <div className="login__form__input__wrap">
                            <div className="login__form__input__icon__box">
                                <RiLockPasswordFill className="login__form__input__icon" />
                            </div>
                            <input
                                type="password"
                                className='login__form__input'
                                name="pword"
                                id="pword"
                                value={pword}
                                onChange={(e) => setPword(e.target.value)}
                                required
                            />
                        </div>
                    </div>

                </div>

                <div className="login__actionbtns">
                    <div className="login__actionbtns__rmbme">
                        <input type="checkbox" id="rmbme" name="rmbme" />
                        <label htmlFor="rmbme">Remember me</label>
                    </div>

                    <div
                        className="login__actionbtns__fgt"
                        onClick={() => { }}
                    >
                        Forgotten Password?
                    </div>
                </div>

                <div className="login__btnsection">
                    <button
                        className='btn login__btn'
                        type='submit'
                    // onClick={() => handleLogin()}
                    >
                        LOGIN
                    </button>
                </div>

            </form>
            <ToastContainer />
        </div>
    )
}

export default Form