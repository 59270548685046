

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getStorage } from "firebase/storage";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyAtJhj5Ze0Pa6tLAWw6WVKC_eFvTS7TDBw",
  authDomain: "skrillaics.firebaseapp.com",
  projectId: "skrillaics",
  storageBucket: "skrillaics.firebasestorage.app",
  messagingSenderId: "734243356786",
  appId: "1:734243356786:web:687df166c9fbef8be342eb",
  measurementId: "G-QMYD6B9JKM"
};

// Initialize Firebase
const DB = initializeApp(firebaseConfig);
const Storage = getStorage();

export {DB, Storage}